import React, { Component } from 'react';
import { Button } from '@material-ui/core';

import 'components/Shared/scss/inner.scss';
import { ReactComponent as VKTeamsLogo } from 'components/Shared/i/logo-VKTeams.svg';
import { openDeeplinkFromLocation } from 'components/deeplink'

class PageHome extends Component {
    componentDidMount() {
        openDeeplinkFromLocation();
    }

    render() {
        return (
            <div className="page">
                <header className="header" id="header">
                    <a href="/" className="header__logo" id="logo" data-goto-section="0">
                        <VKTeamsLogo alt="VK Teams"/>
                    </a>
                </header>
                <div className="download-page background_android" id="downloadLinks">
                    <div style={{width: '294px', margin: '87px auto 0 auto'}}>
                        <Button className="download-page__rtfm-button" href="/downloads/android/latest/vkteams.apk">Скачать</Button>
                    </div>

                    <div className="download-page__rtfm-title">
                        <h2>Первый запуск в Android</h2>
                    </div>

                    <div className="download-page__rtfm-box">
                        <p style={{ fontSize: '20px', marginTop: '24px', lineHeight: '24px' }}>
                            Перед скачиванием приложения вам необходимо разрешить установку из неизвестных источников.
                            Для этого перейдите в
                        </p>

                        <div style={{ backgroundColor: 'rgba(0, 119, 255, 0.2)', padding: '14px 14px 14px 14px', borderRadius: '15px', margin: '14px 0px 0px 0px'}}>
                            <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '20px', lineHeight: '24px' }}>
                                Параметры -> Безопасность -> Неизвестные источники
                            </p>
                        </div>

                        <p style={{ fontSize: '20px', marginTop: '24px', lineHeight: '24px' }}>
                            После этого вы можете скачать и установить приложение.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageHome;
