import { t } from './OTPText';
import { openDeeplink } from '../components/deeplink';

const DOMAIN = window.location.host.replace(/^dl/, '');
const VERSION = 95;
const PROTOCOL = window.location.protocol.concat('//');
const API_HOST = `${PROTOCOL}u${DOMAIN}/api/v${VERSION}`;
const SEND_OTP = '/rapi/dl/sendOtp';
const VERIFY_OTP = '/rapi/dl/verifyOtp';

const encodeURIComponentFull = (s) => { // encodeURIComponent does not encode: ~!*()'
    return encodeURIComponent(s).replace(/~/g, '%7E').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/!/g, '%21').replace(/'/g, '%27').replace(/\*/g, '%2A');
}

const chainParams = (params = {}) => {
    const pairs = [];
    Object.keys(params).forEach((key) => {
        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponentFull(params[key]));
    });
    return pairs.join('&');
}

const getRnd = () => `${Math.round(Math.random() * 99999)}-${Math.floor(new Date().getTime() / 1000)}`;

const makeXHR = (requestUrl, options) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let longWaitTimer;
    const stringParams =
        (requestUrl.indexOf('?') > -1 ? '&' : '?') + chainParams(options.params);
    if (options.params) {
        requestUrl += stringParams;
    }
    xhr.open(options.method || 'GET', requestUrl);
    if (options.withCredentials) {
        xhr.withCredentials = true;
    }
    Object.keys(options.headers || {}).forEach((k) => {
        xhr.setRequestHeader(k, options.headers[k]);
    });
    xhr.onload = () => {
        longWaitTimer && clearTimeout(longWaitTimer);
        let data = xhr.responseText || '';
        try {
            data = JSON.parse(data || 'null');
        } catch (err) {
            console.error(err, data);
            data = null;
        }

        if (xhr.status > 399) {
            reject(xhr);
        } else {
            resolve({ status: xhr.status, data, xhr });
        }
    };
    xhr.onerror = () => {
        longWaitTimer && clearTimeout(longWaitTimer);
        reject(xhr);
    };
    xhr.onabort = () => {
        longWaitTimer && clearTimeout(longWaitTimer);
        reject(xhr);
    };
    if (options.maxTimeout > 0) {
        longWaitTimer = setTimeout(() => {
            xhr.abort();
            reject(xhr);
        }, options.maxTimeout);
    }
    xhr.send(options.data || '');
});

let requested = false;
let linkHash = null;

export const getIOSLink = () => {
    if (linkHash) {
        const protocol = window.location.protocol.concat('//');
        const host = protocol.concat(window.location.host);
        return `itms-services://?action=download-manifest&url=${host}/downloads/ios/${linkHash}/latest/ios_manifest.plist`;
    }
    return '';
};

export const goToIOSLink = () => {
    const href = getIOSLink();
    if (href) {
        openDeeplink(href);
        return true;
    }
    return false;
};

export const OTPServer = {
    submitUsernameForOTP: (email) => new Promise((resolve, reject) => {
        if (!requested) {
            requested = true;
            makeXHR(`${API_HOST}${SEND_OTP}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    reqId: getRnd(),
                    params: {
                        email
                    }
                }),
            }).then(({ status, data }) => {
                requested = false;
                if (OTPServer.requestingSn === email && data?.status?.code === 20000) {
                    resolve();
                } else {
                    reject((OTPServer.requestingSn === email) ? [status, data?.status?.code] : null);
                }
            }, ({ status }) => {
                requested = false;
                reject((OTPServer.requestingSn === email) ? [status] : null);
            });
        }
    }),
    submitUsernamePassword: (email, password) => makeXHR(`${API_HOST}${VERIFY_OTP}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            reqId: getRnd(),
            params: {
                email,
                password,
            }
        }),
    }).then(({ status, data }) => {
        linkHash = data?.results?.hash;
        if (linkHash) {
            return Promise.resolve();
        } else {
            return Promise.reject([status, data?.status?.code]);
        }
    }, ({ status }) => {
        return Promise.reject([status]);
    }).finally(() => {
        requested = false;
    }),
    requestingSn: null
};

export const getServerErrorMessage = (httpCode, statusCode) => {
    const code = statusCode || httpCode;
    let msg = t('auth.msgCommonError');

    if (code === 330) {
        msg = t('auth.msgWrongLoginOrPassword');
    } else if (code === 40500 || code === 465) {
        msg = t('auth.msgRequestRateLimit');
    } else if (code === 401 || code === 40100) {
        msg = t('auth.msgWrongCode');
    } else if (!code) {
        msg = t('auth.msgNetworkError');
    }

    return msg;
};
