import { detectBrowser } from './browser';

var URL_SCHEME = 'myteam-messenger://';
const stampKey = "d"
const allowedArgs = {
    "d": true,
    "start": true,
}

export function openDeeplink(deeplink, failCb) {
    var browser = detectBrowser();
    var func = null;
    if (browser.chrome) func = checkChrome;
    else if (browser.safari && browser.ios) func = checkIos;
    else if (browser.safari || browser.firefox) func = checkIframe;
    if (func) {
        func(deeplink, failCb);
    }
}

export function openDeeplinkFromLocation() {
    var deeplink = getDeeplink(window.location);
    if (deeplink) {
        deeplink = URL_SCHEME + deeplink;
        openDeeplink(deeplink, appNotFound);
    }
}

function getDeeplink(url) {
    const miniAppUrl = getMiniAppDeeplink(url);
    if (miniAppUrl) {
        return miniAppUrl;
    }

    const searchParams = url.search;

    var stamp = "";
    var otherParams = [];
    searchParams.substr(1).split("&").forEach(function (part) {
        let [key, value] = part.split("=");
        if (allowedArgs[key]) {
            if (key === stampKey) {
                stamp = decodeURIComponent(value)
            } else {
                otherParams.push(key+"="+value)
            }
        }
    });
    if (otherParams.length === 0) {
        return stamp;
    }
    return stamp + '?' + otherParams.join('&');
}

export function getMiniAppDeeplink(url) {
    if (/^\/miniapp\//.test(url.pathname)) {
        return `${url.pathname.slice(1)}${url.search}${url.hash}`;
    }
    return '';
}

function checkChrome(runlink, failCb) {
    window.location = runlink;
    setTimeout(function () {
        if (window.isFocused && failCb) failCb();
    }, 500);
}

function checkIframe(runlink, failCb) {
    var ifr = document.createElement('iframe');
    ifr.style.cssText = 'width:0;height:0;position:absolute;';
    ifr.setAttribute('frameborder', '0');
    ifr.setAttribute('id', 'launch_scheme_iframe');
    ifr.setAttribute('src', runlink);
    document.body.appendChild(ifr);

    setTimeout(function () {
        ifr.parentNode.removeChild(ifr);
        if (window.isFocused && failCb) failCb();
    }, 1000);
}

function checkIos(runlink) {
    setTimeout(function() {
        window.location = runlink;
    }, 100);
}

function appNotFound() {
    //just stay on the page
}
window.isFocused = true;
window.addEventListener('focus', function () {
    window.isFocused = true;
});
window.addEventListener('blur', function () {
    window.isFocused = false;
});
