import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOTPContext } from './OTPProvider';
import { getServerErrorMessage, OTPServer } from './OTPServer';
import { t } from './OTPText';
import { REGEX_EMAIL } from '../components/regs';

const otpMailSchema = yup.object().shape({
    otpEmail: yup
        .string()
        .matches(REGEX_EMAIL, {
            message: t('auth.msgWrongLogin'),
            excludeEmptyString: true,
        })
        .required(t('auth.msgWrongLogin')),
});

const OTPMail = () => {
    const {
        otpEmail,
        setOtpEmail,
        setIsPassword,
    } = useOTPContext();
    const [error, setError] = useState('');
    const [requestingSn, setRequestingSn] = useState(OTPServer.requestingSn);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(otpMailSchema),
        defaultValues: {
            otpEmail,
        },
    });

    const otpEmailValue = useWatch({
        control,
        name: 'otpEmail',
        defaultValue: otpEmail,
    });

    const onSubmit = async(date) => {
        const sn = date.otpEmail.trim();
        setOtpEmail(sn);
        setRequestingSn(OTPServer.requestingSn = sn);

        makeAuthRequest(sn);
    };

    const makeAuthRequest = (sn) => {
        setError('');
        OTPServer.submitUsernameForOTP(sn)
            .finally(() => {
                setRequestingSn(OTPServer.requestingSn = null);
            }).then(
                () => {
                    setIsPassword(true);
                },
                ([httpCode, statusCode]) => {
                    setError(getServerErrorMessage(httpCode, statusCode));
                }
            );
    };

    useEffect(() => {
        setRequestingSn(OTPServer.requestingSn = null);
    }, [otpEmailValue, setRequestingSn]);

    return (
        <>
            <span className="imAuthTitle im-auth-title">{t('auth.enterEmail')}</span>
            <span className="imAuthSubtitle im-auth-description">{t('auth.businessOTPAuthEmail')}</span>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <input
                       {...register('otpEmail')}
                       type="text"
                       className="imAuthLogin im-text-input im-auth-input-login"
                       placeholder={t('auth.username')}
                       autoFocus
                />
                <span className="imAuthError im-auth-error">
                    {error || errors?.otpEmail?.message}
                </span>
                <button className="imButton im-auth-submit"
                    data-action="submit"
                    type={'submit'}
                    disabled={!!requestingSn}
                >
                    {t('next')}
                </button>
            </form>
        </>
    );
};

export { OTPMail };
