export function detectBrowser() {
    var ua = navigator.userAgent.toLowerCase();
    var checkUA = function (regexp) {
        return regexp.test(ua);
    };
    var isOpera = checkUA(/opera/) || checkUA(/opr\//),
        isWebKit = checkUA(/webkit/),
        isChrome = !isOpera && checkUA(/chrome/),
        isIE = (!isOpera && checkUA(/msie/)) || (ua.match(/rv:11\.0/i) && ua.match(/Trident\/7\.0/i)),
        isEdge = checkUA(/edge\/\d+/),
        isGecko = !isWebKit && checkUA(/gecko/),
        isFF = isGecko && !isIE,
        isMac = checkUA(/macintosh/),
        isIOS = checkUA(/iphone|ipad|ipod/),
        isSafari = isWebKit && (isMac || isIOS) && !isChrome && !isOpera;

    return {
        opera: isOpera,
        firefox: isFF,
        safari: isSafari,
        chrome: isChrome,
        msie: isIE,
        edge: isEdge,
        ios: isIOS
    };
}
