const textMap = {
    'auth.msgWrongLogin': 'Неверный логин',
    'auth.msgCommonError': 'Произошла ошибка. Попробуйте позже',
    'auth.enterEmail': 'Введите email',
    'auth.username': 'Логин',
    'next': 'Далее',
    'auth.businessOTPAuthEmail': 'Введите ваш корпоративный email, на него будет отправлен пароль для входа',
    'auth.login': 'Войти',
    'auth.password': 'Пароль',
    'auth.businessOTPAuthPassword': 'Введите одноразовый пароль, который был отправлен на ',
    'auth.enterPassword': 'Введите пароль',
    'auth.msgWrongLoginOrPassword': 'Неверный логин или пароль',
    'auth.msgRequestRateLimit': 'Слишком много попыток. Попробуйте позже.',
    'auth.msgWrongCode': 'Неправильный код',
    'auth.msgNetworkError': 'Сетевая ошибка. Попробуйте позже',
};

export const t = (key) => textMap[key] || key;
