import { createContext, useContext } from 'react';

const OTPContext = createContext({});

const useOTPContext = () => {
    return useContext(OTPContext);
};

const OTPProvider = OTPContext.Provider;

export { useOTPContext, OTPProvider };
