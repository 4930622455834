
function copyToClipboard(text, status) {
    var element = document.createElement('textarea');
    element.style.opacity = 0;
    element.style.position = 'fixed';
    element.style.top = 0;
    element.style.left = 0;
    element.value = text;

    document.body.appendChild(element);

    //element.focus();
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
    status.classList.add('-copy');
    setTimeout(function () {
        status.classList.remove('-copy');
    }, 2000);
}

export function copy(e) {
    e.preventDefault();
    copyToClipboard(e.target.innerText, e.target);
}
