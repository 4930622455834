import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PageHome from 'page/Home';
import PageIOSInstaller from 'page/IOSInstaller';
import PageAndroidInstaller from 'page/AndroidInstaller';
import { OTPForm } from '../OTPForm/OTPForm';

const PageRoot = () => (
    <Router>
        <Switch>
            <Route path="/ios" component={PageIOSInstaller} />
            <Route path="/android" component={PageAndroidInstaller} />
            <Route path="/auth" component={OTPForm} />
            <Route path="*" component={PageHome} />
        </Switch>
    </Router>
);

export default PageRoot;
