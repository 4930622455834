import React, { useState } from 'react';

import { OTPProvider } from './OTPProvider';
import { OTPMail } from './OTPMail';
import { OTPPassword } from './OTPPasword';

const OTPForm = () => {
    const [otpEmail, setOtpEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPassword, setIsPassword] = useState(false);

    const handleClickSwitcher = () => {
        setIsPassword(false);
    };

    return (
        <OTPProvider value={{ otpEmail, password, setOtpEmail, setPassword, setIsPassword }}>
            <div className="imAuthForm im-auth-formwrap">
                <div className="frmPassword im-auth-form-password">
                    {isPassword ? <OTPPassword /> : <OTPMail />}
                </div>
            </div>
            {isPassword && (
                <button
                    data-action="switchForm"
                    className="imButtonSwitch im-auth-button-blank im-auth-switch-form"
                    onClick={handleClickSwitcher}
                />
            )}
        </OTPProvider>
    );
};

export { OTPForm };
